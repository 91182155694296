import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import cIcon1 from "../Assets/Images/Icons/Roulette_Icons.png";
import { ChevronLeft } from "lucide-react";

export const AboutUs = () => {
  const { token } = useSelector((store) => store.AuthReducer);
  const [Data, setData] = useState([]);
  const nav = useNavigate();

  const [params, setParams] = useState({
    limit: 1000,
    category: "virtual",
  });

  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://vbet002.com/api/v5/getCasinoListByCategory",
        { ...params }
      );
      const data = response.data;
      setData(data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error gracefully, maybe set an error state to display to the user
    }
  };

  useEffect(() => {
    // fetchData();
  }, [params]);

  return (
    <main className="main">
      <section className="TopCategories">
        <div className="Live-Casino">
          <div className="container">
            <div className="PagesHeading">
              <h2>About Us</h2>
            </div>
            <div className="listings">
              <div className="row">
                <div className="col-12 col-md-12 mb-4">
                  <h2>A Product of Anand Food Processing Industry</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
