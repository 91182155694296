import logo from "../Assets/Images/logo.png";
import {
    ArrowDownUp,
    Dices,
    Lock,
    LogOut,
    Menu,
    MessageCircle,
    ReceiptText,
    UserRound,
    Wallet,
} from "lucide-react";
import menu1 from "../Assets/Images/Icons/menu1.png";
import menu2 from "../Assets/Images/Icons/menu2.png";
import menu3 from "../Assets/Images/Icons/menu3.png";
import menu4 from "../Assets/Images/Icons/menu4.png";
import menu5 from "../Assets/Images/Icons/menu5.png";
import menu6 from "../Assets/Images/Icons/menu6.png";
import menu7 from "../Assets/Images/Icons/menu7.png";
import menu10 from "../Assets/Images/Icons/matka-icon.png";
import voice_d from "../Assets/Images/voice_d.png";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import HomeServices from "../services/HomeServices";

function HeaderV2() {
    const location = useLocation();

    const { token } = useSelector((store) => store.AuthReducer);

    const navigate = useNavigate();

    const [data, setData] = useState(null);
    const [loggedOut, setLoggedOut] = useState(false);
    const user = Cookies.get("user_name") || [];

    const handleChange = () => {
        try {
            // Clear cookies
            document.cookie.split(";").forEach(function (c) {
                document.cookie = c
                    .replace(/^ +/, "")
                    .replace(
                        /=.*/,
                        "=;expires=" + new Date().toUTCString() + ";path=/",
                    );
            });

            // Navigate to "/"
            toast.success("logout successfully", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            window.location.href = "/";
        } catch (error) {
            toast.error("somthing went wrong", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const fetchData = async () => {
        try {
            HomeServices?.getWalletBalance()?.then(({data:response})=>{
            if(response?.status===200){
                setData(response.data);
            }
            }).catch(error=>{
                console.error("error",error);
            })
        } catch (error) {}
    };

    // console.log(data)

    // useEffect(() => {
    //   const intervalId = setInterval(fetchData, 5000);
    //   return () => clearInterval(intervalId);
    //   // fetchData();
    // }, []);

    // console.log(data);

    const handleWallet = () => {
        fetchData();
        // toast.success("Balance updated", {
        //     position: "top-center",
        //     autoClose: 5000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        // });
    };
    useEffect(() => {
        setInterval(() => {
            fetchData();
        }, 5000);
    }, []);
    const handleClick = (path) => {
        navigate(path);
    };

    // date and time
    const [currentDateTime, setCurrentDateTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <React.Fragment>
            <header className="header">
                <nav className="navbar navbar-expand-sm py-0">
                    <div className="container-fluid">
						<Link to="/">
							<img src={logo} alt="Logo" className="navbar-brand logo pb-0"/>
						</Link>
                        {!token ? (
							<div className="headerRight">
								<Link to="/sign-in" type="button" className="button-link btn btn-link"> Login</Link>
								<Link to="/sign-up" type="button" className="button-primary ms-2 btn btn-primary btn-login"> Register</Link>
							</div>
						) : null}
						{token ? (
							<div className="headerRight">
								<ul className="wallet-card">
									<li className="casino_bal" onClick={handleWallet}>
										Bal &nbsp;{" "}
										<strong>
											{data?.wallet}
										</strong>
									</li>
									{/* <Link to={"/reports/bet-history"}>
										<li className="bonus_bal border-0">
											Exp &nbsp;{" "}
											<strong>
												{absoluteValue}
											</strong>
										</li>
									</Link> */}
								</ul>
								<div className="Deposit_btn d-lg-block d-dm-none d-block ms-2">
									<Link to="/deposit" className="btn rounded btn-primary w-100">
										Deposit
									</Link>
								</div>
								<button type="button"className="btn btn- menu-btn" data-bs-toggle="offcanvas" data-bs-target="#ProfileMenu" aria-controls="ProfileMenu">
									<Menu color="#fff" />
								</button>
							</div>
						) : null}
                    </div>
                </nav>
                <nav className="navbar navbar-expand-md header-2">
                    <div className="container-fluid">
                        <button className="navbar-toggler px-2 py-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent2" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent2">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <Link className={ `${location.pathname === "/"? "active": ""} nav-link`} to="/" aria-current="page">
                                        <img src={menu1} alt=""/>
                                        <span>Home</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={ `${location.pathname === "/sports/inplay" ? "active" : ""} nav-link` } to="/sports/inplay" aria-current="page">
                                        <img src={menu2} alt="" />
                                        <span>Sports</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={ `${location.pathname === "/games-all" ? "active" : ""} nav-link` } to="/games-all" aria-current="page">
                                        <img src={menu3} alt="" />
                                        <span>Casino</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={ `${location.pathname === "/about-us" ? "active" : ""} nav-link` } to="/about-us" aria-current="page">
                                        <img src={menu3} alt="" />
                                        <span>About Us</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={ `${location.pathname === "/ezugi" ? "active" : ""} nav-link` } to="/ezugi" aria-current="page">
                                        <img src={menu4} alt="" />
                                        <span>Ezugi</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={ `${location.pathname === "/vsport" ? "active" : ""} nav-link` } to="/vsport" aria-current="page">
                                        <img src={menu5} alt="" />
                                        <span>Virtual Sports</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={ `${location.pathname === "/aviator" ? "active" : ""} nav-link` } to="/aviator" aria-current="page">
                                        <img src={menu6} alt="" />
                                        <span>Aviator</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={ `${location.pathname === "/matka" ? "active" : ""} nav-link` } to="/matka" aria-current="page">
                                        <img src={menu10} alt="" />
                                        <span>Matka</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={ `${location.pathname === "/reports/account-statement" ? "active" : ""} nav-link` } to="/reports/account-statement" aria-current="page">
                                        <img src={menu7} alt="" />
                                        <span>Wallet</span>
                                    </Link>
                                </li>
                            </ul>
                            <div>
                                {!token ? (
                                        <ul className="loggedout navbar-nav me-auto mb-2 mb-lg-0">
                                            <li className="nav-item">
                                                <Link className="nav-link" to="https://wa.me/+919902107947" target="_blank" aria-current="page">
                                                    <MessageCircle style={{fontSize:20}}/>{" "}
                                                    <span>Support</span>
                                                </Link>
                                            </li>
                                        </ul>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </nav>
            </header>

            <div
                role="dialog"
                aria-modal="true"
                className="offcanvas offcanvas-end"
                tabIndex={-1}
                id="ProfileMenu"
                aria-labelledby="ProfileMenuLabel"
            >
                <div className="offcanvas-header">
                    <div className="offcanvas-title h5">
                        <div className="d-flex">
                            <div className="profile-img">
                                <UserRound />
                            </div>
                            <div className="user-info">
                                <span>{user}</span>
                                {/* <p>7796535353</p> */}
                            </div>
                        </div>
                    </div>
                    <button
                        type="button"
                        className="btn-close btn-close-white"
                        aria-label="Close"
                        data-bs-dismiss="offcanvas"
                    />
                </div>
                <div className="offcanvas-body">
                    <div className="row">
                        <div className="col-6">
                            <div className="balance mb-3">
                                <Wallet />
                                Balance
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="balance-amount mb-4">
                                {data?.balance}
                            </div>
                        </div>
                        <div className="col-6">
                            <Link
                                to="/withdraw"
                                data-bs-dismiss="offcanvas"
                                onClick={() => handleClick("/withdraw")}
                                className="btn btn-outline-secondary w-100"
                            >
                                Withdraw
                            </Link>
                        </div>
                        <div className="col-6">
                            <Link
                                to="/deposit"
                                data-bs-dismiss="offcanvas"
                                onClick={() => handleClick("/deposit")}
                                className="btn btn-primary w-100"
                            >
                                Deposit
                            </Link>
                        </div>
                    </div>
                    <ul className="menu-items">
                        <li>
                            <Link
                                to="/change-password"
                                data-bs-dismiss="offcanvas"
                                onClick={() => handleClick("/change-password")}
                            >
                                <Lock />
                                <span>Change Password</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/reports/account-statement"
                                data-bs-dismiss="offcanvas"
                                onClick={() =>
                                    handleClick("/reports/account-statement")
                                }
                            >
                                <ReceiptText />
                                <span>Account Statement</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/reports/profit-loss"
                                data-bs-dismiss="offcanvas"
                                onClick={() =>
                                    handleClick("/reports/profit-loss")
                                }
                            >
                                <ArrowDownUp />
                                <span>Profit and Loss</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/reports/bet-history"
                                data-bs-dismiss="offcanvas"
                                onClick={() =>
                                    handleClick("/reports/bet-history")
                                }
                            >
                                <Dices />
                                <span>My Bets</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/bet-history"
                                data-bs-dismiss="offcanvas"
                                onClick={() => handleClick("/bet-history")}
                            >
                                <Dices />
                                <span>My Bets History</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="#" onClick={handleChange}>
                                <LogOut />
                                <span>Sign Out</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </React.Fragment>
    );
}

export default HeaderV2;
