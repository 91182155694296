import logo from "../Assets/Images/logo.png"
import { Link } from "react-router-dom";
import casino from "../Assets/Images/Icons/casinoIcon.png";
import menu4 from "../Assets/Images/Icons/menu4.png";
import home from "../Assets/Images/Icons/home.png";
import sports from "../Assets/Images/Icons/sportsIcon.png";
import profile from "../Assets/Images/Icons/profile.png";
import facebook from "../Assets/Images/Icons/facebook.png";
import instagram from "../Assets/Images/Icons/instagram.png";
import twitter from "../Assets/Images/Icons/twitter.png";
import youtube from "../Assets/Images/Icons/youtube.png";
import { useSelector } from "react-redux";
import React from "react";
import HomeSection4 from "../Pages/home/HomeSection4";
import { Aviator } from './../Pages/Aviator';
import Contact from './../Pages/StaticPages/Contact';

function FooterV2() {
  const { token } = useSelector((store) => store.AuthReducer);
  
  return (
    <React.Fragment>
      <footer className="footer">
        <HomeSection4/>
        <div className="container footer-box">
          <div className="d-flex justify-content-between flex-wrap">
            <div className="footer-card">
              <legend>Games</legend>
              <div className="footer-links">
                <Link to={`/`}>Crash Games</Link>
                <Link to={`/`}>Online Slots</Link>
                <Link to={`/`}>Online Roulette</Link>
                <Link to={`/`}>Online Black Jack</Link>
                <Link to={`/`}>Online Baccarat</Link>
                <Link to={`/`}>Online Aviator</Link>
                <Link to={`/`}>Online Cards Games</Link>
              </div>
            </div>
            <div className="footer-card">
              <legend>Live Games</legend>
              <div className="footer-links">
                <Link to={`/`}>Live Casino</Link>
                <Link to={`/`}>Games Shows</Link>
                <Link to={`/`}>Live Roulette</Link>
                <Link to={`/`}>Live Black Jack</Link>
                <Link to={`/`}>Live Baccarat</Link>
                <Link to={`/`}>Live Aviator</Link>
                <Link to={`/`}>Live Lotto</Link>
              </div>
            </div>
            <div className="footer-card">
              <legend>Casino Days</legend>
              <div className="footer-links">
                <Link to={`/about-us`}>About us</Link>
                <Link to={`/terms`}>Terms and Conditions</Link>
                <Link to={`/privacy-policy`}>Privacy Policy</Link>
                <Link to={`/`}>Casino App</Link>
                <Link to={`/`}>Promotions</Link>
              </div>
            </div>
            <div className="footer-card">
              <legend>Help</legend>
              <div className="footer-links">
                <Link to={`/`}>Help and FAQ</Link>
                <Link to={`/`}>Customer Support</Link>
                <Link to={`/`}>Verify Account</Link>
                <Link to={`/`}>Payment Methods</Link>
              </div>
            </div>
            <div className="footer-card">
              <legend>More Info</legend>
              <div className="footer-links">
                <Link to={`https://wa.me/+919902107947`}>Contact Us</Link>
                <Link to={`/`}>Bonus Policy</Link>
                <Link to={`/`}>Responsible Gambling</Link>
                <Link to={`/`}>Security and Game Fairness</Link>
                <Link to={`/`}>Affiliates</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center social-links py-3 gap-3">
          <Link to={`/`}><img src={facebook} alt="Facebook" width={35}/></Link>
          <Link to={`/`}><img src={instagram} alt="Instagram" width={35}/></Link>
          <Link to={`/`}><img src={twitter} alt="Twitter" width={35}/></Link>
          <Link to={`/`}><img src={youtube} alt="Youtube" width={35}/></Link>
        </div>
        <div className="text-center">
          <Link><img src={logo} alt={`Logo`} width={150}/></Link>
        </div>
      </footer>
    </React.Fragment>
  );
}

export default FooterV2;
